import { getClutchReviewsData } from 'react-clutch-badge';
import { COMPANY_NAME } from '../common/constants';
import GoogleStructuredData from '../components/Unknown/GoogleStructuredData';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Head from '@/components/Unknown/Head';
import BasePage from '@/components/Internal/Prismic/BasePage';
import { isFilled } from '@prismicio/client';
import { SliceZone } from '@prismicio/react';

import { components } from '@/slices';
import { createClient } from '@/prismicio';

import AppBar from '@/components/Internal/Prismic/AppBar';
import Footer from '@/components/Internal/Prismic/Footer';

export default function Home({
  clutchData,
  page,
  headerData,
  footerData,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  return (
    <BasePage>
      <Head
        metadata={{
          seoTitle: page.data.meta_title as string,
          seoDescription: isFilled.keyText(page.data.meta_description)
            ? page.data.meta_description
            : undefined,
        }}
        imageUrl={(page.data.meta_image?.url as string) || undefined}
      />
      <AppBar transparent={true} headerData={headerData} />
      <SliceZone slices={page.data.slices} components={components} />
      <Footer footerData={footerData} />
      <GoogleStructuredData clutchData={clutchData} />
    </BasePage>
  );
}

export async function getStaticProps({ previewData }: GetStaticPropsContext) {
  // The `previewData` parameter allows your app to preview
  // drafts from the Page Builder.
  const client = createClient({ previewData });

  // The query fetches the page's data based on the current URL.
  const page = await client.getSingle('home');

  // The query fetches the AppBar's data from prismic
  const headerData = await client.getSingle('app_bar');

  // The query fetches the Footer's data from prismic
  const footerData = await client.getSingle('footer');

  const clutchData = await getClutchReviewsData({
    clutchProfileHandle: COMPANY_NAME,
  });

  return {
    props: { page, headerData, footerData, clutchData },
  };
}
