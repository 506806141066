// ! This is a work-in-progress wrapper for Prismic page types.
import { useEffect } from 'react';
import { useRouter } from 'next/router';

interface BasePageProps {
  children?: React.ReactNode;
}

const BasePage: React.FC<BasePageProps> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    if (
      router.isReady &&
      (router.asPath.includes('#nav') ||
        router.asPath.includes('#full-review') ||
        router.asPath.includes('#mobile-full-review'))
    ) {
      router.replace(
        router.asPath
          .replace('#nav', '')
          .replace('#full-review', '')
          .replace('#mobile-full-review', ''),
        undefined,
        {
          shallow: true,
          scroll: false,
        },
      );
    }
  }, [router]);

  return <>{children}</>;
};

export default BasePage;
