import { useState, useEffect } from 'react';
import HeadBase from 'next/head';
import { useRouter } from 'next/router';
import OgImage from './../../../common/assets/img/og-img.webp';

export interface Metadata {
  seoTitle?: string;
  seoDescription?: string;
}

type HeadProps = {
  metadata: Metadata;
  imageUrl?: string;
};

const Head: React.FC<HeadProps> = ({ metadata, imageUrl }) => {
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const { seoTitle, seoDescription } = metadata;

  // Ensure imageUrl is always a string
  const resolvedImageUrl =
    imageUrl || (typeof OgImage === 'string' ? OgImage : OgImage.src);

  useEffect(() => {
    if (router.isReady) setCurrentUrl(window.location.href);
  }, [router]);

  return (
    <HeadBase>
      <title>
        {seoTitle ||
          'Arcanys | Custom Software Development & Team Augmentation Company'}
      </title>
      <meta
        name="description"
        content={
          seoDescription ||
          "Philippines' Top Software Development & Team Augmentation company. Hire the tech talents you need from a pool of 200+ dedicated software engineers."
        }
      />
      {resolvedImageUrl && (
        <meta property="og:image" content={resolvedImageUrl} />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta
        property="fb:app_id"
        content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@arcanys" />
      <link rel="canonical" href={currentUrl} />
    </HeadBase>
  );
};

export default Head;
