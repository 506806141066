import Script from 'next/script';
import { getClutchReviewsData } from 'react-clutch-badge';
import { COMPANY_NAME } from '../../../common/constants';
import { ClutchBadgeData } from '../../../common/types';

export const getStaticProps = async () => {
  const clutchData = await getClutchReviewsData({
    clutchProfileHandle: COMPANY_NAME,
  });
  return {
    props: {
      clutchData,
    },
  };
};

interface GoogleStructured {
  clutchData: ClutchBadgeData;
}

const GoogleStructuredData: React.FC<GoogleStructured> = ({ clutchData }) => {
  return (
    <Script
      // eslint-disable-next-line react/no-danger
      strategy="lazyOnload"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
          {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "image": [
                  "https://www.arcanys.com/pictures/Arcanys-Developers.jpg",
                  "https://www.arcanys.com/pictures/Arcanys-Founders-Alan-Debonneville-Frederic-Joye.jpg",
                  "https://www.arcanys.com/pictures/Fun-Medulla-team-Arcanys.jpg",
                  "https://www.arcanys.com/pictures/Late-coding-flexible-schedule.jpg",
                  "https://www.arcanys.com/pictures/Standup-meeting-Arcanys.jpg",
                  "https://www.arcanys.com/pictures/Standup-meeting.jpg"
              ],
              "@id": "https://www.arcanys.com",
              "name": "Arcanys",
              "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Salinas Drive",
                  "addressLocality": "10th Floor JY IT Center 3 Bldg.",
                  "addressRegion": "Cebu City",
                  "postalCode": "6000",
                  "addressCountry": "Philippines"
              },
              "review": {
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "${clutchData.starsCount || 4.9}",
                  "bestRating": "${clutchData.starsCount || 4.9}"
                },
                "author": {
                  "@type": "Organization",
                  "name": "Arcanys"
                }
              },
              "priceRange": "$$$",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "${clutchData.starsCount || 4.9}",
                "reviewCount": "${clutchData.reviewsCount || 16}"
              },
              "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 10.330928,
                  "longitude": 123.89882
              },
              "email": "hello@arcanys.com",
              "telephone": "(032) 416 5414"
          }
        `,
      }}
    />
  );
};

export default GoogleStructuredData;
