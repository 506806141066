import { useRef, useEffect, useState } from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Slide from '@material-ui/core/Slide';
import AppBarDesktop from '@/components/Unknown/AppBarDesktop';
import AppBarMobile from '@/components/Unknown/AppBarMobile';
import LetsConnectCard from '@/components/Unknown/LetsConnectCard';
import Box from '@/components/Unknown/Box';
import Container from '@/components/Unknown/Container';
import AppBarAnimatedBackground from '@/components/Unknown/AppBarAnimatedBackground';
import {
  APPBAR_DESKTOP_HEIGHT,
  APPBAR_MOBILE_HEIGHT,
} from '@/common/constants';

import { Content, isFilled } from '@prismicio/client';

interface AppBarProps {
  transparent?: boolean;
  headerData: Content.AppBarDocument;
}

interface HideOnScrollProps {
  children: React.ReactElement;
}

interface MenuItemElement {
  target?: string;
  rel?: string;
  text: string;
  link: string;
  tagline?: string;
}
interface MenuItem {
  text: string;
  link?: string;
  description?: string;
  children?: MenuItemElement[];
}

const HideOnScroll: React.FC<HideOnScrollProps> = ({ children }) => {
  const trigger = useScrollTrigger({ threshold: 0 });

  return (
    <Slide direction="down" in={!trigger} timeout={200}>
      {children}
    </Slide>
  );
};

// Processes the header data object to extract and organize menu items and their sub-navigation items into a structured array format.
const headerDataCleanup = (headerData: Content.AppBarDocument): MenuItem[] => {
  const menuItems: MenuItem[] = [];
  const addSubNav = (
    parentName: string,
    childrenObjArray: MenuItemElement[],
  ) => {
    const parent = menuItems.find((item) => item.text === parentName);
    if (parent) {
      parent.children = parent.children || [];
      parent.children.push(...childrenObjArray);
    }
  };

  for (const slice of headerData.data.slices) {
    const text = slice.primary.name as string;
    const link = isFilled.link(slice.primary.link)
      ? slice.primary.link.url
      : '';
    const description = slice.primary.description as string;

    menuItems.push({ text, link, description });

    if (slice.primary.child_navigation.length > 0) {
      const childrenNav = slice.primary.child_navigation.map((item) => ({
        text: item.child_name as string,
        link:
          isFilled.link(item.child_link) && item.child_link.url
            ? item.child_link.url
            : '',
        target:
          isFilled.link(item.child_link) && item.child_link.link_type === 'Web'
            ? item.child_link.target
            : '',
        tagline: item.tagline as string,
      }));
      addSubNav(text, childrenNav);
    }
  }

  return menuItems;
};

const AppBar: React.FC<AppBarProps> = ({ transparent = false, headerData }) => {
  const appBarRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const menuItems = headerDataCleanup(headerData);
  // Using useWindowScroll causes laggy scrolling on Mac Safari.
  // Below custom scrolling detection fixes this.
  // const { y: scrollY } = useWindowScroll();
  const { scrollY, scrolled } = (() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState({
      scrollY: 0,
      scrolled: false,
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const handler = () => {
        setState(() => {
          const { pageYOffset } = window;
          return {
            scrollY: pageYOffset,
            scrolled: pageYOffset > 150,
          };
        });
      };

      handler();
      fromEvent(window, 'scroll')
        .pipe(debounceTime(500))
        .subscribe(() => {
          handler();
        });
    }, []);

    return state;
  })();

  const isLightTheme = !transparent || scrolled;
  const borderColor = isLightTheme ? theme.palette.grey[200] : 'transparent';

  return (
    <HideOnScroll>
      <Box
        height={{ xs: APPBAR_MOBILE_HEIGHT, md: APPBAR_DESKTOP_HEIGHT }}
        width={1}
        className="mui-fixed"
        zIndex={1250}
        position="fixed"
        display="flex"
        alignItems="center"
        boxShadow={`0 2px 0 0 ${borderColor}`}
        ref={appBarRef}
      >
        <AppBarAnimatedBackground scrollY={scrollY} transparent={transparent} />
        <Container>
          {isMobile ? (
            <AppBarMobile
              menuItems={menuItems}
              lightTheme={isLightTheme}
              letsConnectCard={<LetsConnectCard />}
            />
          ) : (
            <AppBarDesktop
              menuItems={menuItems}
              slogan={headerData.data.slogan as string}
              lightTheme={isLightTheme}
            />
          )}
        </Container>
      </Box>
    </HideOnScroll>
  );
};

export default AppBar;
